import "../styles/globals.css";

import { SessionProvider } from "@empathy/common-web-auth";
import { EmpathyUIProvider } from "@empathy/common-web-components";
import {
  DEFAULT_LOCALE,
  EmpathyProvider,
  ErrorBoundry,
} from "@empathy/common-web-core";
import * as Sentry from "@sentry/nextjs";
import type { AppProps } from "next/app";
import { useEffect } from "react";

import { BlockMobile } from "../components/BlockMobile";
import { AppLayout, NextPageWithLayout } from "../components/layout/AppLayout";
import * as otpAuth from "../lib/auth/otpAuthentication";
import * as locales from "../locales";
import { analytics } from "../modules/core/analytics";
import { usePartnerSlug } from "../modules/partners/usePartnerSlug";

interface MyAppProps extends AppProps {
  pageProps: any;
  Component: NextPageWithLayout;
}
function App({ Component, pageProps }: MyAppProps) {
  const { partnerSlug } = usePartnerSlug();
  const appLayout = Component.appLayout;
  const screenName = Component.screenName;

  useEffect(() => {
    if (partnerSlug) {
      analytics.setUserProperties({ partner: partnerSlug });
    }
  }, [partnerSlug]);

  return (
    <BlockMobile>
      <EmpathyProvider
        language={{
          initialLocale: DEFAULT_LOCALE,
          locales,
        }}
        authConfig={{ refreshTokenMethod: otpAuth.refreshToken }}
      >
        <EmpathyUIProvider>
          <ErrorBoundry onError={(error) => Sentry.captureException(error)}>
            <SessionProvider>
              <AppLayout layout={appLayout} screenName={screenName}>
                <Component {...pageProps} />
              </AppLayout>
            </SessionProvider>
          </ErrorBoundry>
        </EmpathyUIProvider>
      </EmpathyProvider>
    </BlockMobile>
  );
}

export default App;
