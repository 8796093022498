import { safeLocalStorage } from "@empathy/common-web-core";
import { useState } from "react";

import { getPartnerSlug } from "./getPartnerSlug";

export const PARTNER_SLUG_KEY = "part.slug";

export const usePartnerSlug = () => {
  const [partnerSlug, setPartnerSlug] = useState<string | null | undefined>(
    safeLocalStorage().getItem(PARTNER_SLUG_KEY),
  );

  const setPartnerSlugAndPersist = (
    newPartnerSlug: string | null | undefined,
  ) => {
    if (newPartnerSlug === partnerSlug) {
      return;
    }

    setPartnerSlug(newPartnerSlug);

    // Set the partner slug from the partner repo or use the parameter directly in case of setting the partner from the familyAdvocate
    safeLocalStorage().setItem(
      PARTNER_SLUG_KEY,
      getPartnerSlug(newPartnerSlug) || newPartnerSlug || "",
    );
  };

  return { partnerSlug, setPartnerSlug: setPartnerSlugAndPersist };
};
