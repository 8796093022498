import { signOut as nextAuthLogout } from "@empathy/common-web-auth";
import { useAuthenticationContext } from "@empathy/common-web-core";

export const useLogout = () => {
  const { logout: commonLogout } = useAuthenticationContext();

  const logout = () => {
    commonLogout();
    void nextAuthLogout({ redirect: false });
  };

  return { logout };
};
