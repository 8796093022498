/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { safeLocalStorage } from "@empathy/common-web-core";
import {
  arrow,
  autoUpdate,
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  Placement,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import * as React from "react";
import { useEffect } from "react";

interface PopoverOptions {
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  showOnceLocalStorageKey?: string;
  overlayType?: "hideAllExceptTrigger" | "placementArea";
  mainAxisOffset?: number;
  crossAxisOffset?: number;
}

const getIsOpen = (showOnceLocalStorageKey?: string) => {
  if (showOnceLocalStorageKey) {
    const value = safeLocalStorage().getItem(showOnceLocalStorageKey);
    return value === null;
  }
  return true;
};

export function usePopover(
  {
    placement = "right-start",
    arrowRef,
    showOnceLocalStorageKey,
    overlayType,
    mainAxisOffset,
    crossAxisOffset,
  }: PopoverOptions & {
    arrowRef: React.MutableRefObject<null> | null;
  } = {
    arrowRef: null,
  },
) {
  const [open, setOpen] = React.useState(getIsOpen(showOnceLocalStorageKey));

  const acknowledge = React.useCallback(() => {
    if (showOnceLocalStorageKey) {
      safeLocalStorage().setItem(showOnceLocalStorageKey, "true");
      setOpen(false);
    }
  }, [showOnceLocalStorageKey]);

  const data = useFloating({
    placement,
    open,
    onOpenChange: acknowledge,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: mainAxisOffset, crossAxis: crossAxisOffset }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const context = data.context;
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      acknowledge,
      ...interactions,
      ...data,
      arrowRef,
      overlayType,
    }),
    [open, acknowledge, interactions, data, arrowRef, overlayType],
  );
}

type ContextType = ReturnType<typeof usePopover> | null;

const PopoverContext = React.createContext<ContextType>(null);

export const usePopoverContext = () => {
  const context = React.useContext(PopoverContext);

  if (context == null) {
    throw new Error("Popover components must be wrapped in <Popover />");
  }

  return context;
};

export function Popover({
  children,
  showOnceLocalStorageKey,
  overlayType,
  mainAxisOffset,
  crossAxisOffset,
  ...restOptions
}: {
  children: React.ReactNode;
} & PopoverOptions) {
  const arrowRef = React.useRef(null);
  const popover = usePopover({
    arrowRef,
    showOnceLocalStorageKey,
    overlayType,
    mainAxisOffset,
    crossAxisOffset,
    ...restOptions,
  });

  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  );
}

const Overlay = ({
  overlayType,
}: {
  overlayType?: "hideAllExceptTrigger" | "placementArea";
}) => {
  const { context } = usePopoverContext();

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  if (!context.open) return null;
  const position = context.elements.reference?.getBoundingClientRect();

  if (overlayType === "hideAllExceptTrigger") {
    return (
      <div
        style={{
          top: position?.top,
          left: position?.left,
          width: position?.width,
          height: position?.height,
          boxShadow: "0px 0px 0px 100000px rgba(30, 25, 40, 0.64)", //plum-64
        }}
        className="rounded-full fixed z-50 overflow-hidden"
      />
    );
  }

  if (overlayType === "placementArea") {
    return (
      <div
        style={{
          left: position?.right,
        }}
        className={
          "fixed top-0 w-screen h-screen bg-plum-64 z-50 overflow-hidden"
        }
      />
    );
  }
  return null;
};

interface PopoverTriggerProps {
  children: React.ReactNode;
  asChild?: boolean;
}

export const PopoverTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & PopoverTriggerProps
>(function PopoverTrigger({ children, asChild = false, ...props }, propRef) {
  const context = usePopoverContext();

  const childrenRef = (children as any).ref as React.Ref<any>;

  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      }),
    );
  }

  return (
    <button
      ref={ref}
      type="button"
      // The user can style the trigger based on the state
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

export const PopoverContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
  // eslint-disable-next-line react/prop-types
>(function PopoverContent({ style, ...props }, propRef) {
  const { context: floatingContext, ...context } = usePopoverContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  floatingContext.update();
  if (!floatingContext.open) return null;

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floatingContext} initialFocus={-1}>
        <>
          <div
            ref={ref}
            style={{ ...context.floatingStyles, ...style, zIndex: 999999 }}
            {...context.getFloatingProps(props)}
          >
            {props.children}
            <FloatingArrow
              className="fill-white"
              ref={context.arrowRef}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //   @ts-ignore
              context={context}
            />
          </div>
        </>
      </FloatingFocusManager>
      <Overlay overlayType={context.overlayType} />
    </FloatingPortal>
  );
});
