import { ReactNode } from "react";

import { withoutSSR } from "../../hocs/withoutSSR";
import { BI_SCREENS } from "../../modules/core/analytics";
import { PageLayout } from "./PageLayout";

export const ResourcePageLayout = withoutSSR(
  ({
    children,
    screenName,
  }: {
    children: ReactNode;
    screenName: BI_SCREENS;
  }) => {
    return (
      <PageLayout screenName={screenName}>
        <div className="px-[88px] xl:px-8 2xl:px-[80px] max-w-[1186px] w-full">
          {children}
        </div>
      </PageLayout>
    );
  },
);
