import { Menu } from "@headlessui/react";
import React, { ComponentProps, ReactElement } from "react";

import {
  analytics,
  BI_CATEGORIES,
  BI_EVENTS,
} from "../../modules/core/analytics";

type MenuItemProps = {
  title: string;
  icon?: ReactElement;
  clickEventName?: BI_EVENTS;
} & ComponentProps<"button">;

export const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  title,
  clickEventName,
  onClick,
  ...props
}) => (
  <Menu.Item>
    <button
      {...props}
      onClick={(e) => {
        if (clickEventName) {
          analytics.trackEvent(BI_CATEGORIES.Portal, clickEventName);
        }
        onClick?.(e);
      }}
      className="text-sm text-body-small flex cursor-pointer px-8 py-5 text-plum hover:bg-plum-4 w-full"
    >
      {icon}
      <span className="ml-4">{title}</span>
    </button>
  </Menu.Item>
);
