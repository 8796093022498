import clsx from "clsx";
import Head from "next/head";
import { ReactNode, useEffect } from "react";

import { withoutSSR } from "../../hocs/withoutSSR";
import { useAppAuthentication } from "../../lib/auth/useAppAuthentication";
import {
  analytics,
  BI_CATEGORIES,
  BI_SCREENS,
} from "../../modules/core/analytics";
import { AuthorizationProvider } from "./AuthorizationProvider";
import { LayoutProvider } from "./navigation/LayoutProvider";
import { Sidebar } from "./navigation/Sidebar";
import { useNavigationItems } from "./navigation/useNavigationItems";
import { PageHeader } from "./PageHeader";

export const PageLayout = withoutSSR(
  ({
    children,
    screenName,
  }: {
    children: ReactNode;
    screenName: BI_SCREENS;
  }) => {
    useAppAuthentication();
    const { navSections } = useNavigationItems();
    const showSidebar =
      navSections.length > 1 || navSections[0]?.items.length > 1;

    useEffect(() => {
      analytics.trackScreen(BI_CATEGORIES.Portal, screenName);
    }, []);

    return (
      <AuthorizationProvider>
        <Head>
          <title>Empathy Connect</title>
        </Head>
        <LayoutProvider>
          <div className="relative flex min-h-screen flex-row">
            <PageHeader withSidebar={showSidebar} />
            {showSidebar && <Sidebar />}
            <div
              className={clsx(
                "pt-16 flex flex-col items-center pb-24 w-full overflow-hidden",
                { "xl:ml-[254px]": showSidebar },
              )}
            >
              {children}
            </div>
          </div>
        </LayoutProvider>
      </AuthorizationProvider>
    );
  },
);
