import { Button, Icons, Skeleton } from "@empathy/common-web-components";
import { parseName, useTranslation } from "@empathy/common-web-core";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import Image from "next/image";
import { useRouter } from "next/router";
import { Fragment } from "react";

import { contactUs } from "../../apps/gateway/lib/zendesk";
import { useLogout } from "../../lib/auth/useLogout";
import { Routes } from "../../lib/routes";
import {
  analytics,
  BI_CATEGORIES,
  BI_EVENTS,
} from "../../modules/core/analytics";
import { useAgency } from "../../modules/core/useAgency";
import { useMyPortalRole } from "../../modules/core/useMyPortalRole";
import { usePartner } from "../../modules/partners/usePartner";
import { MenuItem } from "./MenuItem";

export enum UserMenuDataHooks {
  MenuButton = "UserMenu.MenuButton",
  LogoutButton = "UserMenu.LogoutButton",
  ContactUs = "UserMenu.ContactUs",
  ManageProfile = "UserMenu.ManageProfile",
}

export const Avatar = ({
  firstName,
  photoUrl,
  size,
  imageAlt,
}: {
  firstName?: string;
  photoUrl?: string | null;
  imageAlt: string;
  size?: "small" | "medium" | "large" | "xlarge";
}) => {
  const sizeToPixels = {
    small: 32,
    medium: 52,
    large: 88,
    xlarge: 96,
  };
  return (
    <div
      className={clsx(
        "flex shrink-0 items-center justify-center rounded-full bg-sky text-white font-medium",
        (!size || size === "small") && "h-8 w-8 text-body-standard",
        size === "medium" && "h-[52px] w-[52px] text-body-standard",
        size === "large" && "h-[88px] w-[88px] text-[38px]",
        size === "xlarge" && "h-24 w-24 text-[38px]",
      )}
    >
      {photoUrl ? (
        <Image
          src={photoUrl}
          alt={imageAlt}
          width={size ? sizeToPixels[size] : 32}
          height={size ? sizeToPixels[size] : 32}
          className="rounded-full"
        />
      ) : (
        firstName?.charAt(0)?.toUpperCase() ?? ""
      )}
    </div>
  );
};

export const UserMenu = () => {
  const { loading, profile, email } = useMyPortalRole();
  const { loading: agencyLoading, agency } = useAgency();
  const { logout } = useLogout();
  const firstName = parseName(profile?.fullName ?? undefined).firstName;
  const { data } = usePartner();
  const { push } = useRouter();
  const { t } = useTranslation();

  if (loading && !profile) {
    return <Skeleton variant="circle" className="h-8 w-8" />;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              data-testid={UserMenuDataHooks.MenuButton}
              onClick={() => {
                analytics.trackEvent(
                  BI_CATEGORIES.Portal,
                  BI_EVENTS.ProfileMenuClicked,
                );
              }}
            >
              <Avatar
                firstName={firstName}
                photoUrl={profile?.photoUrl}
                imageAlt={t("userMenu.avatarImageAlt")}
              />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="flex-1 absolute mt-2 right-0 w-[374px] rounded-md focus:outline-none shadow-[0_2px_10px_0_rgba(0,0,0,0.2)] bg-white">
              <>
                <div className="p-8">
                  <div className="flex flex-col gap-8">
                    {data?.logo?.url && data?.partnerName && (
                      <div className="h-10 w-24 relative">
                        <Image
                          src={data.logo.url}
                          layout="fill"
                          objectFit="contain"
                          objectPosition="left"
                          alt={data.partnerName}
                        />
                      </div>
                    )}
                    <div className="flex gap-4 items-start overflow-hidden">
                      <Avatar
                        firstName={firstName}
                        photoUrl={profile?.photoUrl}
                        size={"medium"}
                        imageAlt="Profile Picture"
                      />
                      <div className="flex flex-col gap-1 justify-center">
                        <span className="text-h2 text-plum">
                          {profile?.fullName}
                        </span>
                        <span className="text-body-small text-plum-64 truncate max-w-60">
                          {email}
                        </span>
                        {!agencyLoading && agency && (
                          <div className="flex bg-plum-4 rounded-full border-t border-plum-4 py-[2px] px-2 w-fit mt-[14px]">
                            <span className="text-body-small text-plum-64">
                              {agency.name}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-t border-plum-8" />
                <div>
                  <MenuItem
                    data-testid={UserMenuDataHooks.ManageProfile}
                    clickEventName={BI_EVENTS.ManageProfileClicked}
                    onClick={() => void push(Routes.Profile())}
                    title="Manage Profile"
                    icon={<Icons.PersonSettings />}
                  />
                  <MenuItem
                    data-testid={UserMenuDataHooks.ContactUs}
                    clickEventName={BI_EVENTS.UserMenuContactUsClicked}
                    onClick={contactUs}
                    title="Help & Support"
                    icon={<Icons.QuestionCircle />}
                  />
                  <div className="border-t border-plum-8" />
                  <Button
                    className="mx-8 my-6 text-plum-64 ring-plum-64 ring-[1px] rounded-md"
                    title="Sign Out"
                    data-testid={UserMenuDataHooks.LogoutButton}
                    variant="bordered"
                    onClick={() => {
                      analytics.trackEvent(
                        BI_CATEGORIES.Portal,
                        BI_EVENTS.UserMenuLogoutClicked,
                      );
                      logout();
                    }}
                  />
                </div>
              </>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
