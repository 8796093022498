export const BookIcon = ({ ...props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 6.17v14.078M12 6.17c0-.783.576-1.454 1.368-1.594l5.833-.883c1.024-.181 1.965.583 1.965 1.594v12.22c0 .784-.576 1.455-1.368 1.595L12 20.332l-7.798-1.23c-.793-.14-1.369-.811-1.369-1.594V5.287c0-1.011.942-1.775 1.965-1.594l5.833.883c.793.14 1.369.811 1.369 1.594Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
