import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";

import { useLayoutContext } from "./LayoutProvider";
import { SidebarContent } from "./SidebarContent";

export const Sidebar = () => {
  const { sidebarOpen, setSidebarOpen } = useLayoutContext();

  return (
    <div className="relative">
      {/* Mobile Menu */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 xl:hidden"
          onClose={() => setSidebarOpen(false)}
        >
          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full flex-1 max-w-[293px]">
                <div
                  className={clsx(
                    "z-30 fixed inset-y-0 flex flex-col bg-[rgb(246,246,247)] shadow-[0_2px_10px_0_rgba(0,0,0,0.2)] w-[293px]",
                  )}
                >
                  <SidebarContent />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Desktop Sidebar */}
      <div
        className={clsx(
          "hidden z-30 xl:fixed xl:inset-y-0 xl:bg-plum-4 xl:w-[254px] xl:flex xl:flex-col xl:shadow-none",
        )}
      >
        <SidebarContent />
      </div>
    </div>
  );
};
