import { NextPage } from "next/types";
import React from "react";

import { BI_SCREENS } from "../../modules/core/analytics";
import { PageLayout } from "./PageLayout";
import { ResourcePageLayout } from "./ResourcePageLayout";

export type NextPageWithLayout<P = any, IP = P> = NextPage<P, IP> & {
  appLayout?: Layout;
  screenName: BI_SCREENS;
};

export enum Layout {
  ResourcesPage,
  ActionPage,
  Empty,
  NoSidebar,
}
interface LayoutProps {
  children: React.ReactNode;
  layout?: Layout;
  screenName: BI_SCREENS;
}

export const AppLayout: React.FC<LayoutProps> = ({
  children,
  layout = Layout.Empty,
  screenName,
}) => {
  if (layout === Layout.ActionPage) {
    return <PageLayout screenName={screenName}>{children}</PageLayout>;
  }

  if (layout === Layout.ResourcesPage) {
    return (
      <ResourcePageLayout screenName={screenName}>
        {children}
      </ResourcePageLayout>
    );
  }

  return <>{children}</>;
};
