import { useQuery } from "@apollo/client";
import { useAuthenticationContext } from "@empathy/common-web-core";

import { Agency, MyPortalRoleDocument } from "../../generated/graphql/schema";

export const useAgency = () => {
  const { isAuth } = useAuthenticationContext();
  const { loading, error, data } = useQuery(MyPortalRoleDocument, {
    skip: !isAuth,
  });

  return {
    loading,
    agency: data?.myPortalRole?.agency as Agency,
    error,
  };
};
