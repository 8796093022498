import { useAuthenticationContext } from "@empathy/common-web-core";
import Router from "next/router";
import { useEffect } from "react";

import { Routes } from "../routes";

export const useAppAuthentication = () => {
  const { isAuth } = useAuthenticationContext();

  useEffect(() => {
    if (!isAuth) {
      void Router.push(Routes.Home());
    }
  }, [isAuth]);
};
