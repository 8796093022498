import { useTranslation } from "@empathy/common-web-core";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import { useLayoutContext } from "./LayoutProvider";
import { SidebarItem } from "./types";

export type NavItemProps = SidebarItem & {
  withSectionHeader: boolean;
};

export const NavItem = ({
  titleKey,
  href,
  icon,
  withSectionHeader,
}: NavItemProps) => {
  const { pathname } = useRouter();
  const active = pathname.toLowerCase() === href.toLocaleLowerCase();
  const { t } = useTranslation();
  const { setSidebarOpen } = useLayoutContext();
  return (
    <Link href={href} passHref>
      <button
        data-testid={`NavItem.${titleKey}`}
        onClick={() => setSidebarOpen(false)}
        className={clsx(
          "text-plum relative flex h-full cursor-pointer items-center rounded-lg font-medium hover:bg-white/50 active:bg-white/75",
          {
            "bg-white": active,
            "text-body-small pl-12 p-3": withSectionHeader,
            "text-body-standard p-4": !withSectionHeader,
          },
        )}
      >
        <div
          className={clsx("flex gap-3", {
            "text-h5": withSectionHeader && active,
            "text-h4": !withSectionHeader && active,
          })}
        >
          {!withSectionHeader && (
            <div className={clsx({ "text-[rgb(75,71,83)]": !active })}>
              {icon}
            </div>
          )}
          {t(titleKey)}
        </div>
      </button>
    </Link>
  );
};
