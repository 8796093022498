import Router, { useRouter } from "next/router";
import React, { useEffect } from "react";

import { useLogout } from "../../lib/auth/useLogout";
import { useMyPortalRole } from "../../modules/core/useMyPortalRole";
import { LoadingScreen } from "../common/LoadingScreen";
import { useNavigationItems } from "./navigation/useNavigationItems";

export const AuthorizationProvider: React.FC = ({ children }) => {
  const { loading } = useMyPortalRole();
  const { navSections, loading: navigationLoading } = useNavigationItems();
  const { logout } = useLogout();
  const { pathname } = useRouter();

  const [authLoading, setAuthLoading] = React.useState(true);

  useEffect(() => {
    if (!navSections.length) {
      return;
    }

    const allSidebarTabs = navSections.flatMap((section) => section.items);
    if (!allSidebarTabs.map((tab) => tab.href).includes(pathname)) {
      void Router.push(navSections[0].items[0].href);
    } else {
      setAuthLoading(false);
    }
  }, [navSections, pathname]);

  if (loading || navigationLoading || authLoading) {
    return <LoadingScreen />;
  }

  if (!navSections.length) {
    logout();
    return null;
  }

  return <>{children}</>;
};
