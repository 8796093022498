import { getSession, signIn } from "@empathy/common-web-auth";
import { AuthenticationService } from "@empathy/common-web-core";

import { otpProvider } from "./providerPicker";

interface AuthParams {
  email: string;
  code?: string;
}

export const startAuthentication = async (email: string) => {
  return signIn(otpProvider, { redirect: false, email });
};

export const authenticateUserWithCode = async (
  params: Required<AuthParams>,
) => {
  const response = await signIn(otpProvider, { redirect: false, ...params });

  if (response?.error) {
    throw new Error(response.error);
  }

  const session = await getSession();

  if (session) {
    const accessToken = session.accessToken;
    AuthenticationService.setAccessToken(accessToken || "");
  }
};

export const refreshToken = async () => {
  const session = await getSession();

  if (session) {
    const accessToken = session.accessToken || "";
    AuthenticationService.setAccessToken(accessToken);
    return accessToken;
  }

  throw new Error("No session found");
};
