import { useFetch } from "@empathy/common-web-core";

import { GatewayPartner } from "../../generated/contentful/schema";
import { usePartnerSlug } from "./usePartnerSlug";

export const usePartner = () => {
  const { partnerSlug } = usePartnerSlug();

  return useFetch<GatewayPartner>(`/api/partner/${partnerSlug || ""}`, {
    skip: !partnerSlug,
  });
};
