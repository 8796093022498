import { LoadingAnimation } from "@empathy/common-web-components";

export enum LoadingScreenDataHooks {
  LoadingElement = "LoadingScreen.LoadingElement",
}

export const LoadingScreen = ({
  loadingMessage = "Gathering your information...",
}: {
  loadingMessage?: string;
}) => {
  return (
    <div
      data-testid={LoadingScreenDataHooks.LoadingElement}
      className="fixed flex h-full w-full flex-col items-center justify-center"
    >
      <LoadingAnimation className="mb-4 h-6" />
      <span className="text-body-standard text-plum-80">{loadingMessage}</span>
    </div>
  );
};
