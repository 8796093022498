export const otpProvider = "email-otp";
const ssoProviders = ["metlife-sso", "jumpcloud-sso"] as const;

type providers = (typeof ssoProviders)[number] | typeof otpProvider;

const domainToProvider: Record<string, providers> = {
  "metlife.com": "metlife-sso",
};

const emailToProvider: Record<string, providers> = {
  "sso-oidc-test@empathy.com": "jumpcloud-sso",
};

export const pickProvider = (email: string): providers | undefined => {
  const emailDomain = email.toLowerCase().trim().split("@")[1];
  return domainToProvider[emailDomain] || emailToProvider[email] || otpProvider;
};

export const pickSignInMethod = (email: string): "sso" | "email-otp" => {
  const provider = pickProvider(email);
  return provider === "email-otp" ? "email-otp" : "sso";
};
