import { EmpathyLogo } from "@empathy/common-web-components";
import { useRouter } from "next/router";

import { Routes } from "../../lib/routes";

export const PortalLogo: React.FC = () => {
  const { push } = useRouter();

  return (
    <div className="flex items-center h-18 w-full select-none">
      <button onClick={() => void push(Routes.Home())}>
        <div className="flex flex-row  px-6 gap-[6px] hover:cursor-pointer w-full">
          <div className="flex shrink-0 items-center">
            <EmpathyLogo className="h-6 text-plum-80" />
          </div>
          <span className="leading-[32px] text-[22px] font-normal text-plum-80 mb-1">
            connect
          </span>
        </div>
      </button>
    </div>
  );
};
