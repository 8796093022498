export const VaultIcon = ({ ...props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          d="M1.995 6a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v10A4.002 4.002 0 0 1 19 19.873V21a1 1 0 1 1-2 0v-1H7v1a1 1 0 1 1-2 0v-1.125A4.002 4.002 0 0 1 1.995 16V6m4-2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-12M13 11a2 2 0 1 0-4 0 2 2 0 0 0 4 0m3-1h-1.126A4.002 4.002 0 0 0 7 11a4 4 0 0 0 7.874 1H16a1 1 0 1 0 0-2"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
