import dynamic from "next/dynamic";
import React from "react";

export function withoutSSR<P extends object>(
  Component: React.ComponentType<P>,
): React.ComponentType<P> {
  const Comp = (props: P) => <Component {...props} />;

  Comp.displayName = `${Component.displayName ?? ""}-withoutSSR`;

  return dynamic(() => Promise.resolve(Comp), { ssr: false });
}
