export const ChatDoubleIcon = ({ ...props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10.5 17.5h4.959a.1.1 0 0 1 .07.03l3.264 3.263c.63.63 1.707.184 1.707-.707v-2.49c0-.053.043-.096.095-.096v0a1.905 1.905 0 0 0 1.905-1.905V9.5a2 2 0 0 0-2-2v0"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M1.5 4.5a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H8.541a.1.1 0 0 0-.07.03l-3.264 3.263c-.63.63-1.707.184-1.707-.707v-2.49a.095.095 0 0 0-.095-.096A1.905 1.905 0 0 1 1.5 11.595z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
