export const FlowerRoseIcon = ({ ...props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#a)" stroke="currentColor" strokeWidth="2">
        <path d="M9.207 20.332A8.84 8.84 0 0 1 3 11.871V7.792c0-.412.173-.8.487-1.081a1.458 1.458 0 0 1 1.14-.363 8.914 8.914 0 0 1 3.178.99 8.893 8.893 0 0 1 3.44 3.294" />
        <path d="M11.899 20.745c-.933 0-1.833-.14-2.691-.413a.62.62 0 0 1-.43-.586V17.65c0-2.18.62-4.293 1.792-6.117.024-.041.041-.074.066-.1a11.271 11.271 0 0 1 5.226-4.308 11.41 11.41 0 0 1 3.31-.793 1.456 1.456 0 0 1 1.585 1.445v4.11c0 4.88-3.97 8.85-8.85 8.85l-.008.009Z" />
        <path d="M7.002 7.536a18.838 18.838 0 0 1 2.93-3.285l1.008-.892a1.413 1.413 0 0 1 1.882 0l1.007.892c1.024.9 1.957 1.94 2.774 3.087" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M2 2h20v20H2z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
