import { Icons, Separator } from "@empathy/common-web-components";
import clsx from "clsx";

import { Popover, PopoverContent, PopoverTrigger } from "../../common/Popover";
import { PortalLogo } from "../PortalLogo";
import {
  CONNECT_ANNOUNCEMENT_ACKNOWLEDGED_KEY,
  ConnectAnnouncement,
} from "./ConnectAnnouncement";
import { useLayoutContext } from "./LayoutProvider";
import { NavSection } from "./NavSection";
import { useNavigationItems } from "./useNavigationItems";

export const SidebarContent = () => {
  const { navSections } = useNavigationItems();

  const multipleSections = navSections.length > 1;
  const { setSidebarOpen, isXLScreen } = useLayoutContext();

  return (
    <>
      <Popover
        showOnceLocalStorageKey={CONNECT_ANNOUNCEMENT_ACKNOWLEDGED_KEY}
        overlayType="placementArea"
        mainAxisOffset={32}
        crossAxisOffset={16}
      >
        <div className="flex">
          <PopoverTrigger>
            <PortalLogo />
          </PopoverTrigger>
          <button
            className="mr-4 xl:hidden"
            onClick={() => setSidebarOpen(false)}
          >
            <Icons.Close className="text-plum-80 mb-[1px]" />
          </button>
        </div>
        {isXLScreen && (
          <PopoverContent>
            <ConnectAnnouncement />
          </PopoverContent>
        )}
      </Popover>
      <div
        className={clsx(
          "flex flex-col px-3",
          multipleSections ? "pt-4" : "pt-3",
        )}
      >
        {navSections.map((section) => (
          <div key={section.title}>
            {section.isAdditionalSection && multipleSections && <Separator />}
            <NavSection
              section={section}
              withHeader={multipleSections}
              isAdditionalSection={section.isAdditionalSection}
            />
          </div>
        ))}
      </div>
    </>
  );
};
