import { Analytics } from "@empathy/common-web-core";

export type { AnalyticsProperties } from "@empathy/common-web-core/types";

export enum BI_CATEGORIES {
  Portal = "PORTAL",
}

export enum BI_SCREENS {
  Invites = "INVITES",
  Onboarding = "ONBOARDING",
  Resources = "RESOURCES",
  Reporting = "REPORTING",
  Faq = "FAQ",
  Signin = "SIGNIN",
  NotFound = "NOT_FOUND",
  Profile = "PROFILE",
  LifeFaq = "LIFE.FAQ",
  LifeResources = "LIFE.RESOURCES",
  LifeInvites = "LIFE.INVITES",
}

export enum BI_EVENTS {
  SendPhonelessInvitationClicked = "SendPhonelessInvitation Clicked",
  PhonelessInvitationOpened = "PhonelessInvitation Opened",
  SendInvitesClicked = "SendInvites Clicked",
  SendInvitesAgainClicked = "SendInvitesAgain Clicked",
  ClearFormClicked = "Clear form Clicked",
  ResendCodeClicked = "Resend-code Clicked",
  ProfileMenuClicked = "Profile-menu Clicked",
  SignedIn = "Signed-in",
  VerificationCodeSubmitted = "Verification-code submitted",
  UserMenuContactUsClicked = "User-menu contact-us clicked",
  UserMenuLogoutClicked = "User-menu logout clicked",
  FaqQuestionOpened = "Faq Question opened",
  FaqQuestionClosed = " Faq Question closed",
  TalkingPointsOpened = "Talking points opened",
  TalkingPointsClosed = "Talking points closed",
  TalkingPointsTabClicked = "Talking points tab clicked",
  TalkingPointsTopicClicked = "Talking points topic clicked",
  ManageProfileClicked = "Manage profile clicked",
}

export const analytics = new Analytics<BI_CATEGORIES, BI_SCREENS>().init();
