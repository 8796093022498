import { PortalPermissionType } from "../../../generated/graphql/schema";
import { Routes } from "../../../lib/routes";
import { BookIcon } from "../../icons/BookIcon";
import { ChartBarIcon } from "../../icons/ChatBarIcon";
import { ChatDoubleIcon } from "../../icons/ChatDoubleIcon";
import { FlowerRoseIcon } from "../../icons/FlowerRoseIcon";
import { PersonCircleIcon } from "../../icons/PersonCircleIcon";
import { VaultIcon } from "../../icons/VaultIcon";
import { SidebarSection } from "./types";

export const bereavementSupport: SidebarSection = {
  title: "sidebar.bereavement.title",
  icon: <FlowerRoseIcon />,
  items: [
    {
      titleKey: "sidebar.bereavement.items.invites",
      href: Routes.Bereavement.Invites(),
      icon: <PersonCircleIcon />,
      requiredPermission: [PortalPermissionType.AdvocateDefault],
    },
    {
      titleKey: "sidebar.bereavement.items.faq",
      href: Routes.Bereavement.Faq(),
      icon: <ChatDoubleIcon />,
      requiredPermission: [PortalPermissionType.AdvocateResources],
    },
    {
      titleKey: "sidebar.bereavement.items.resources",
      href: Routes.Bereavement.Resources(),
      icon: <BookIcon />,
      requiredPermission: [PortalPermissionType.AdvocateResources],
    },
  ],
};

export const estatePlanning: SidebarSection = {
  title: "sidebar.estate-planning.title",
  icon: <VaultIcon />,
  items: [
    {
      titleKey: "sidebar.estate-planning.items.invite",
      href: Routes.EstatePlanning.Invites(),
      icon: <PersonCircleIcon />,
      requiredPermission: [PortalPermissionType.Agent],
    },
    {
      titleKey: "sidebar.estate-planning.items.faq",
      href: Routes.EstatePlanning.Faq(),
      icon: <ChatDoubleIcon />,
      requiredPermission: [PortalPermissionType.Agent],
    },
    {
      titleKey: "sidebar.estate-planning.items.resources",
      href: Routes.EstatePlanning.Resources(),
      icon: <BookIcon />,
      requiredPermission: [PortalPermissionType.Agent],
    },
  ],
};

export const connect: SidebarSection = {
  title: "sidebar.connect.title",
  icon: <ChartBarIcon />,
  isAdditionalSection: true,
  items: [
    {
      titleKey: "sidebar.connect.title",
      href: Routes.Bereavement.Reporting(),
      icon: <ChartBarIcon />,
      requiredPermission: [PortalPermissionType.ReportingDefault],
    },
  ],
};

export const sidebarSections = [bereavementSupport, estatePlanning, connect];
