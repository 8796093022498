import { Button, Icons } from "@empathy/common-web-components";
import { useTranslation } from "@empathy/common-web-core";
import Image from "next/image";
import React from "react";

import { usePopoverContext } from "../../common/Popover";

export const CONNECT_ANNOUNCEMENT_ACKNOWLEDGED_KEY =
  "connect-announcement-acknowledged";

export const ConnectAnnouncement = () => {
  const { t } = useTranslation();
  const { acknowledge } = usePopoverContext();

  return (
    <div className="p-8 bg-white rounded-lg shadow-lg w-[405px] flex flex-col-reverse">
      <div className="relative">
        <Image
          alt="" // alt text should remain empty for decorative images
          src="/images/illustrations/connect-popover.svg"
          layout="responsive"
          width={353}
          height={128}
        />
        <h1 className="text-h3 pt-8 pb-2">
          {t("sidebar.popover.connect.title")}
        </h1>
        <h2 className="text-plum-80 text-[14px] pb-8">
          {t("sidebar.popover.connect.description")}
        </h2>
        <Button
          size="rounded-medium"
          variant="solid"
          title={t("sidebar.popover.connect.cta")}
          onClick={acknowledge}
        />
      </div>
      <Icons.Close
        className="text-plum-80 mb-4 ml-auto"
        onClick={acknowledge}
      />
    </div>
  );
};
