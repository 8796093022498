import { useTranslation } from "@empathy/common-web-core";
import Router, { useRouter } from "next/router";

import { NavItem } from "./NavItem";
import { SidebarSection } from "./types";

export const NavSection = ({
  section,
  withHeader,
  isAdditionalSection,
}: {
  section: SidebarSection;
  withHeader: boolean;
  isAdditionalSection?: boolean;
}) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  const isSectionOpen =
    isAdditionalSection ||
    section.items.some(
      (item) => item.href.toLocaleLowerCase() === pathname.toLowerCase(),
    );

  const shouldRenderHeader = withHeader && !isAdditionalSection;

  return (
    <div className="flex flex-col">
      {shouldRenderHeader && (
        <button
          onClick={() => {
            if (!isSectionOpen) {
              void Router.push(section.items[0].href);
            }
          }}
        >
          <div className="flex gap-3 pl-3 py-4">
            <div className="text-[rgb(75,71,83)]">{section.icon}</div>
            <div className="text-plum text-body-standard font-medium">
              {t(section.title)}
            </div>
          </div>
        </button>
      )}
      {isSectionOpen &&
        section.items.map((item) => (
          <NavItem
            key={item.titleKey}
            {...item}
            withSectionHeader={shouldRenderHeader}
          />
        ))}
    </div>
  );
};
