export const PersonCircleIcon = ({ ...props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#a)" stroke="currentColor" strokeWidth="2">
        <path
          d="M12 12.151a3.26 3.26 0 1 0 0-6.518 3.26 3.26 0 0 0 0 6.518"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M18.973 19.47c-1.45-2.834-4.03-4.72-6.973-4.72-2.979 0-5.586 1.932-7.025 4.823" />
        <circle cx="12" cy="12" r="10" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M1 1h22v22H1z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
