import { useMyPortalRole } from "../../../modules/core/useMyPortalRole";
import { sidebarSections } from "./sidebarConfig";
import { SidebarItem, SidebarSection } from "./types";

export const useNavigationItems = (): {
  loading: boolean;
  navSections: SidebarSection[];
} => {
  const { permissions, loading } = useMyPortalRole();
  let sections: SidebarSection[] = [];

  if (!loading && !permissions) {
    return { loading, navSections: sections };
  }

  const filterByPermission = (item: SidebarItem) => {
    const { requiredPermission } = item;
    return (
      !requiredPermission ||
      requiredPermission.some((p) => permissions?.includes(p))
    );
  };

  sections = sidebarSections.reduce((sections, curr) => {
    const filteredTabs = curr.items.filter(filterByPermission);
    if (filteredTabs.length) {
      sections.push({ ...curr, items: filteredTabs } as SidebarSection);
    }
    return sections;
  }, [] as SidebarSection[]);

  return { loading, navSections: sections };
};
