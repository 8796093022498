import { safeLocalStorage } from "@empathy/common-web-core";
import { deleteCookie, getCookie, setCookie } from "cookies-next";

import { CONNECT_ANNOUNCEMENT_ACKNOWLEDGED_KEY } from "../../components/layout/navigation/ConnectAnnouncement";

const ONBOARDING_TOKEN_COOKIE_NAME = "em-ob";

export const useOnboardingFlow = () => {
  const startOnboarding = (token: string) => {
    setCookie(ONBOARDING_TOKEN_COOKIE_NAME, token, { maxAge: 2592000 });
    safeLocalStorage().setItem(CONNECT_ANNOUNCEMENT_ACKNOWLEDGED_KEY, "true"); // Mark the Connect rebranding announcement as seen
  };

  const completeOnboarding = () => {
    deleteCookie(ONBOARDING_TOKEN_COOKIE_NAME);
  };

  const onboardingToken = getCookie(ONBOARDING_TOKEN_COOKIE_NAME);

  return {
    pendingOnboarding: onboardingToken && onboardingToken !== "",
    onboardingToken: onboardingToken?.toString() || "",
    startOnboarding,
    completeOnboarding,
  };
};
