export const ChartBarIcon = ({ ...props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20.557 18.875H3.442M9.604 18.873V8.605h4.792"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M19.187 4.5h-4.792v14.375h4.792V4.5M4.813 18.876v-6.161h4.791"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
