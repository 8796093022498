import { useQuery } from "@apollo/client";
import { useAuthenticationContext } from "@empathy/common-web-core";
import { useEffect } from "react";

import { MyPortalRoleDocument } from "../../generated/graphql/schema";
import { useLogout } from "../../lib/auth/useLogout";
import { useOnboardingFlow } from "../../lib/onboarding/useOnboardingFlow";

export const useMyPortalRole = () => {
  const { isAuth } = useAuthenticationContext();
  const { logout } = useLogout();
  const { pendingOnboarding } = useOnboardingFlow();
  const { loading, error, data } = useQuery(MyPortalRoleDocument, {
    skip: !isAuth,
  });

  useEffect(() => {
    if (!isAuth || pendingOnboarding) {
      return;
    }

    // Handle scenario where user is logged in with pre common-web-auth access token
    if (error || (!loading && data?.myPortalRole === null)) {
      logout();
    }
  }, [error, logout, isAuth, loading]);

  return {
    loading,
    authId: data?.myPortalRole?.authId,
    email: data?.myPortalRole?.email,
    lifeTenantId: data?.myPortalRole?.lifeTenantId,
    permissions: data?.myPortalRole?.permissions,
    partner: data?.myPortalRole?.partner,
    organization: data?.myPortalRole?.organization,
    profile: data?.myPortalRole?.profile,
    error,
  };
};
