import { useMediaQuery } from "@empathy/common-web-components";
import { createContext, ReactNode, useContext, useState } from "react";

interface ILayoutContext {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
  isXLScreen: boolean;
}

export const LayoutContext = createContext<ILayoutContext>(
  undefined as unknown as ILayoutContext,
);

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayoutContext must be used within a LayoutProvider");
  }
  return context;
};

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isXLScreen = useMediaQuery("only screen and (min-width: 1280px)");

  return (
    <LayoutContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        isXLScreen,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
