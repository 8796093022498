import { EmpathyLogo, useMediaQuery } from "@empathy/common-web-components";
import Image from "next/image";
import React, { useEffect, useState } from "react";

import { hide, show } from "../apps/gateway/lib/zendesk";

export enum BlockMobileDataHooks {
  BlockMobile = "BlockMobile.BlockMobile",
}

export const BlockMobile: React.FC = ({ children }) => {
  const isMobile = useMediaQuery("only screen and (max-width: 760px)");
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setShouldShow(true);
      hide();
    } else {
      setShouldShow(false);
    }

    return () => show();
  }, [isMobile]);

  if (shouldShow) {
    return (
      <div
        className="flex h-screen w-screen flex-col"
        data-testid={BlockMobileDataHooks.BlockMobile}
      >
        <div className="flex items-center justify-center p-4">
          <div className="flex shrink-0 items-center">
            <EmpathyLogo className="h-6 text-plum-80" />
          </div>
          <div className="mx-2 h-10 border-r border-plum-8" />
          <span className="text-h2 font-normal text-plum-80">Gateway</span>
        </div>
        <div className="flex flex-1 flex-col place-content-center whitespace-pre-wrap px-8 pb-32 text-center">
          <Image
            src="/images/illustrations/mobile-phone.svg"
            width={88}
            height={88}
            layout="intrinsic"
            alt="Mobile Phone"
          />
          <h1 className="text-e6 my-8 mb-4">
            We don’t support this{"\n"}screen size yet.
          </h1>
          <p className="text-body-standard text-plum-80">
            Please login from your desktop computer{"\n"}to use Empathy Gateway.
          </p>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
